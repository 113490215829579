import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import useTheme from '@mui/material/styles/useTheme';
import { Link } from "gatsby";
import { useBreakpoint } from "../_shared/contextBreakpointMediaQ";
import WavedFrameAnimationForChildrenLazy from './WavedFrameAnimationForChildrenLazy';
import { color } from 'framer-motion';
import zIndex from '@mui/material/styles/zIndex';


const sxStyleList = {
    "&.obj": {
        width: '100%',
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        borderRadius: "20px",
        borderStyle: "groove",
        // bgcolor: "var(--color-info-main)",
        // color: "var(--color-info-text)",
        bgcolor: "primary2.main",
        color: "primary2.contrastText",
        // maxWidth: "fit-content",
        textAlign: "center",
        transition: "all 0.5s",
        // transform: "rotateY(50deg)",
        "& svg.svgFrame": {
            zIndex: 1,
        },
        "& .link": {
            color: "unset",
        },
        "& .MuiListSubheader-root": {
            // bgcolor: "primary.main",
            // color: "primary.contrastText",
            borderRadius: "20px",
            textShadow: "0 0 black",
            // fontSize: "1rem",
            fontSize: [ "medium" ],

            // backgroundColor: '#b0bec5',
            // color: 'rgba(0, 0, 0, 0.87)',
            // textShadow: '0 0 black',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            borderBottomLeftRadius: 'unset',
            borderBottomRightRadius: 'unset',
            marginBottom: "0.75rem",
        },
        "& .objw": {
            borderRadius: "20px",
            // borderStyle: "groove",
            ml: "20px",
            my: "0.5rem",
            // "& .MuiTypography-root": {
            //     fontSize: "unset"
            // },
            "& .MuiListItem-root": {
                // fontSize: [ "smaller", "medium" ],
                py: 0, // [ 0.5, 1, 2 ],
                "& .MuiListItemText-root .MuiTypography-root": {
                    // color: "primary.contrastText",
                    // fontSize: "1.2rem",
                    fontSize: [ "small", "smaller", "medium" ],
                    // color: "var(--color-info-text)",

                },
            },
        },
        "& .wstr": {
            "&.MuiListItemText-root": {
                // fontSize: [ "medium" ],
                my: 0, py: 0,
                "& .MuiTypography-root": {
                    // color: "primary.contrastText",
                    // fontSize: "1.2rem",
                    fontSize: [ "small", "smaller", "medium" ],
                    // color: "var(--color-info-text)",
                },

            },

        }
    }
}

const ServicesAbout = () => {
    const breakpoint = useBreakpoint()
    const theme = useTheme()
    const namePaddingsFitXs = [ "xs", "sm" ].includes(breakpoint) ? "T20-R0-B20-L0" : "T20-R20-B20-L20"

    return (
        <List
            sx={sxStyleList}
            className="obj"
            subheader={<ListSubheader className="clay" >Na web7.sk si môžte objednať </ListSubheader>}
        >
            <WavedFrameAnimationForChildrenLazy
                name="wavedFrWeb"
                namePaddings={namePaddingsFitXs}
                // stylesTop={[ { stroke: theme.palette.warning.dark, fill: theme.palette.warning.main }, { stroke: theme.palette.error.dark, fill: theme.palette.error.main } ]}
                stylesTop={[ { stroke: theme.palette.warning.dark, fill: theme.palette.warning.main }, { stroke: theme.palette.error.dark, fill: theme.palette.error.main } ]}
                stylesRight={[ { stroke: theme.palette.warning.dark, fill: theme.palette.warning.main }, { stroke: theme.palette.error.dark, fill: theme.palette.error.main } ]}
                stylesBottom={[ { stroke: theme.palette.warning.dark, fill: theme.palette.warning.main }, { stroke: theme.palette.error.dark, fill: theme.palette.error.main } ]}
                stylesLeft={[ { stroke: theme.palette.warning.dark, fill: theme.palette.warning.main }, { stroke: theme.palette.error.dark, fill: theme.palette.error.main } ]}

                nameWavedFrames="baseT2R2B2L2_vrana"
                // {...optionsWavedFrameAnimationForChildren}
                key="wavedFrWeb"
            >
                <Link to="/tvorba-web-stranky/"
                    className="link"
                    key="linklink1"
                >
                    <ListItem>
                        <ListItemIcon>
                            <DeveloperBoardIcon />
                        </ListItemIcon>
                        <ListItemText className="wstr" primary="webstránky" />
                    </ListItem>
                </Link>
            </WavedFrameAnimationForChildrenLazy>
            <List
                className="objw"
            >
                <ListItem>
                    <ListItemIcon>
                        <PermMediaIcon />
                    </ListItemIcon>
                    <ListItemText id="prof" primary="s dizajnom podľa vás" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <BrightnessAutoIcon />
                    </ListItemIcon>
                    <ListItemText id="kv" primary="vykonávajúce funkcie podľa vás" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <NetworkCheckIcon />
                    </ListItemIcon>
                    <ListItemText id="rych" primary="super rýchle" />
                </ListItem>

            </List>
            <WavedFrameAnimationForChildrenLazy
                name="wavedFrObjExc"
                nameWavedFrames="baseT2R2B2L2_cushion"
                namePaddings={namePaddingsFitXs}
                stylesTop={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}
                stylesRight={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}
                stylesBottom={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}
                stylesLeft={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, ]}

                // {...optionsWavedFrameAnimationForChildren}
                key="wavedFrObjExc"
            >
                <Link to="/programovanie-excel/"
                    className="link"
                    key="linklink2"
                >
                    <ListItem>
                        <ListItemIcon>
                            <AutoGraphIcon />
                        </ListItemIcon>
                        <ListItemText className="wstr" primary="alebo utilitu pre vaše dáta v Exceli" />
                    </ListItem>
                </Link>
            </WavedFrameAnimationForChildrenLazy>
        </List>
    )
}

export default ServicesAbout