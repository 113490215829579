import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { alpha } from "@mui/material";

// import AnimateScrollIntoViewportLazy from '../../_shared/AnimateScrollIntoViewportLazy';
import HomeTitle from './HomeTitle';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_RECTANGLE_10_POINTS_RIGHT, POLYGON_STAR } from '../../../utils/clipPathsConstants';
import ServicesAbout from "../../_shared/ServicesAbout";
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
import SeparatorAbsSvg2 from "../../_shared/shaping/SeparatorAbsSvg2";

const FullUp = (props) => {

    const { title, breakpoint } = props
    const theme = useTheme()
    const bgPrimaryDark = theme.palette.primary2.dark
    // const refServices = useRef(null)

    const stylesBottom = [ { strokeWidth: '13', stroke: theme.palette.warning.main }, { strokeWidth: '15', stroke: theme.palette.info.main } ]
    const sepFillColor = theme.palette.primary2.dark
    const sepFillColorContrastText = theme.palette.primary2.contrastText
    return (<>
        <Container
            maxWidth={false}
            className="cont contUp"
            key="conttitle"
            sx={{
                position: "relative",
                pt: 0, // [2, 4, 6, 8],
                pb: 0,
                px: [ 0.5, 1, 2, 3 ],
                transformStyle: "preserve-3d",
                mb: "3rem",
            }}
        >
            <div
                className="wrapTitleBoost"
                style={{
                    width: "100%",
                    perspective: "1000px",
                }}
            >
                {/* <Suspense fallback={<div>Loading AnimateScrollIntoViewport Title ...</div>}> */}
                {/* <AnimateScrollIntoViewportLazy
                    name={`homeTitle-${breakpoint}`}
                    key={`homeTitle-${breakpoint}`}
                >
                </AnimateScrollIntoViewportLazy> */}
                <HomeTitle title={title} breakpoint={breakpoint} />
                {/* </Suspense> */}
            </div>

        </Container>
        <Container
            maxWidth="lg" //{false}
            className="cont cont3D"
            key="cont3D"
        >

            <Paper
                className="paperSubt clay"
                elevation={24}
                // sx={borderRadius: '50%',padding: ['25%','50%']}
                sx={{
                    bgcolor: "unset",
                    color: "unset",
                }}
            >
                {/* <Suspense fallback={<div>Loading WavedFrameAnimationForChildren ...</div>}> */}
                <WavedFrameAnimationForChildrenLazy
                    // {...optionsWavedFrameAnimationForChildren}
                    // optionsFrames={{
                    //     top:{
                    //         createPaddings: true,
                    //     }
                    // }}
                    name="wavedSpravneInview"
                    nameWavedFrames="baseT2R2B2L2_circ" //</Paper> "baseB2"
                    namePaddings="T10-R20-B10-L20"
                    // stylesBottom= {[ {fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ]}
                    // stylesBottom= {[ {fill: theme.palette.warning.main }, { fill: theme.palette.info.main } ]}
                    stylesBottom={[ ...stylesBottom ]}
                    key="wavedFr Tvorba-web-stranok"
                >

                    <Typography
                        variant="h2"
                        component="h2"
                        id="Tvorba-web-stranok"
                        className="subTitle toc toc0"
                        sx={{ fontSize: [ "small", "medium", "large", "x-large" ] }}
                    >
                        <span>Tvorba webstránok</span>
                    </Typography>
                </WavedFrameAnimationForChildrenLazy>
                {/* </Suspense> */}
                {/* <hr /> */}
                {/* <Suspense fallback={<div>Loading WavedFrameAnimationForChildren ...</div>}> */}
                <WavedFrameAnimationForChildrenLazy
                    // {...optionsWavedFrameAnimationForChildren}
                    // optionsFrames={{
                    //     top:{
                    //         createPaddings: true,
                    //     }}
                    // }
                    nameWavedFrames="baseT2R2B2L2_cushion"
                    // namePaddings="T10-R10-B10-L10"
                    namePaddings="T10-R20-B10-L20"
                    // stylesTop= {[ {strokeWidth: '13', stroke: theme.palette.text, fill:theme.palette.primary.dark }, { strokeWidth: '5',stroke: theme.palette.info.main } ]}
                    key="wavedFr - Excel"
                >
                    <Typography
                        variant="h2"
                        component="h2"
                        id="Tvorba-aplikacii-pre-excel"
                        className="subTitle toc toc0 clay"
                        sx={{ fontSize: [ "small", "medium", "large", "x-large" ] }}
                    >

                        <span>Tvorba aplikácií pre Excel</span>
                    </Typography>
                </WavedFrameAnimationForChildrenLazy>
                {/* </Suspense> */}
            </Paper>
            {/* <Suspense fallback={<div>Loading AnimateScrollIntoViewport ...</div>}> */}

            {/* </Suspense> */}
            <div style={{
                transform: "translateZ(0)",
                // minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
                id="services"
                // ref={refServices}
                key="textServices"
            >
                {/* <Suspense 
        // fallback={<div>Loading Sep AnimRef  ...</div>}
        > */}

                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="ServicesAbout"
                    passedControllingInViewport={false}
                    optionsSep={{
                        dPathOrderNotIn: [ 2 ],
                        styleWrapSep: { lineHeight: "inherit" },
                        styleSvg: {
                            height: "50px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,
                        }}
                    >
                        <Container maxWidth="md" className='cont'  >
                            <AnimateScrollIntoViewport
                                durationIn={0.5}
                                durationOut={0.2}
                                animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, backgroundColor: alpha(bgPrimaryDark, 0), }}
                                exitFramer={{ clipPath: POLYGON_STAR, backgroundColor: alpha(bgPrimaryDark, 0.5), }}
                                styleToMerge={{ clipPath: POLYGON_STAR, backgroundColor: alpha(bgPrimaryDark, 0.5), }}
                                name="ServicesAbout"
                            // refParent={refServices}
                            >
                                <ServicesAbout />
                            </AnimateScrollIntoViewport>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </div>
        </Container>
    </>
    );
};

FullUp.propTypes = {
    children: PropTypes.node,
};

export default FullUp;
